import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BAZIS_API_V, BazisSrvService } from '@bazis/shared/services/srv.service';
import {
    BehaviorSubject,
    combineLatest,
    merge,
    Observable,
    of,
    shareReplay,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { EntData, EntList, GroupRequestItem } from '@bazis/shared/models/srv.types';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { buildFilterStr } from '@bazis/utils';
import { ListPagination } from '@bazis/list/models/list.types';
import { SHARE_REPLAY_SETTINGS } from '@bazis/configuration.service';
import { debounceTime, filter, map, take, tap } from 'rxjs/operators';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { BazisToastService } from '@bazis/shared/services/toast.service';

const TAB_PARAMS = {
    all: {},
    emergency: {
        template__notice_level: 'danger',
    },
    important: {
        is_important: 'true',
    },
    informing: {
        template__notice_level: 'info',
    },
};

const ALL_TABS = [
    {
        id: 'all',
        titleKey: 'listStaticFilter.all',
        titleParams: { count: 0 },
    },
    // {
    //     id: 'emergency',
    //     titleKey: 'tab.emergency',
    //     titleParams: { count: 0 },
    // },
    {
        id: 'important',
        titleKey: 'tab.important',
        titleParams: { count: 0 },
    },
    // {
    //     id: 'informing',
    //     titleKey: 'tab.informing',
    //     titleParams: { count: 0 },
    // },
];

export const DISABLE_NOTIFICATIONS_ROLES_DATA = new InjectionToken<{ [index: string]: any }>('', {
    providedIn: 'root',
    factory: () => [],
});

@Injectable({ providedIn: 'root' })
export class BazisNotificationService {
    isLoading = new TemplateObservable(true);

    protected _updateTopNotificationsList$ = new BehaviorSubject(true);

    protected _updateTabsCount$ = new BehaviorSubject(true);

    protected channelField = this.bazisApiV === '2' ? 'provider' : 'channel';

    protected sentState = this.bazisApiV === '2' ? 'sent' : 'send';

    pagination: TemplateObservable<ListPagination> = new TemplateObservable({
        offset: 0,
        limit: 20,
        count: 0,
    });

    constructor(
        protected srv: BazisSrvService,
        protected socketService: BazisWebSocketService,
        protected entityService: BazisEntityService,
        protected authService: BazisAuthService,
        protected toastService: BazisToastService,
        @Inject(DISABLE_NOTIFICATIONS_ROLES_DATA) public disableNotificationsRoles: string[],
        @Inject(BAZIS_API_V) public bazisApiV,
    ) {
        combineLatest([this.authService.userId$, this.authService.role$])
            .pipe(
                debounceTime(100),
                tap((v) => {
                    this._newNotifications.set([]);
                    console.log('clr new notifications');
                }),
            )
            .subscribe();
    }

    nextPage$ = new BehaviorSubject(1);

    filter$ = new BehaviorSubject({});

    tab$ = new BehaviorSubject('all');

    protected _newNotifications = new TemplateObservable([]);

    notificationsFromSocket$ = this.socketService.message$.pipe(
        filter(
            (v) =>
                v &&
                (v?.$snapshot?.notice_type === 'notice.message' ||
                    v?.$snapshot?.notice_type === 'notice.system' ||
                    v?.$snapshot?.notice_type === 'notice.toast'),
        ),
        map((v) => {
            return {
                ...v,
                $snapshot: {
                    ...v.$snapshot,
                    state: this.sentState,
                    is_important: false,
                },
            };
        }),
        tap((v) => {
            const newNotifications = this._newNotifications._;
            newNotifications.unshift(v);
            this._newNotifications.set(newNotifications);
            this._updateTabsCount$.next(true);
            this.nextPage$.next(this.nextPage$.value);
            this._updateStorage([v]);
            if (v?.$snapshot?.notice_type === 'notice.toast') {
                const stateMap = {
                    success: 'success',
                    negative: 'error',
                    danger: 'error',
                    info: 'warning',
                };
                this.toastService.create({
                    type: stateMap[v?.$snapshot?.notice_level],
                    title: v?.$snapshot?.title,
                    message: v?.$snapshot?.body,
                });
            }
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected _notificationStorage = new TemplateObservable({});

    protected _filterChanged$ = this.filter$.pipe(
        tap((v) => {
            this.nextPage$.next(1);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected _tabFilters$ = this.tab$.pipe(
        map((tab) => {
            return TAB_PARAMS[tab] || null;
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    tabList$ = merge(this._filterChanged$, this._updateTabsCount$).pipe(
        debounceTime(0),
        withLatestFrom(this._filterChanged$),
        switchMap(([v, filters]) =>
            this.entityService.getListsCount$(
                ALL_TABS.map((tab) => ({
                    entityType: 'notifications.notice',
                    filters: {
                        ...filters,
                        ...TAB_PARAMS[tab.id],
                        [this.channelField]: 'websocket',
                    },
                })),
            ),
        ),
        map((response) => {
            let i = 0;
            return [...ALL_TABS].map((tab) => {
                if (!TAB_PARAMS[tab.id]) return tab;
                return {
                    ...tab,
                    titleParams: { count: response[i++] },
                };
            });
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // list of notifications
    protected _listFromRequest$: Observable<EntData[]> = combineLatest([
        this.nextPage$,
        this._filterChanged$,
        this._tabFilters$,
    ]).pipe(
        debounceTime(0),
        tap((v) => this.isLoading.set(true)),
        switchMap(([page, filters, tabFilters]) =>
            this.entityService.getEntityList$('notifications.notice', {
                params: {
                    filter: buildFilterStr({
                        ...filters,
                        ...tabFilters,
                        [this.channelField]: 'websocket',
                    }),
                },
                offset: (page - 1) * this.pagination._.limit,
                limit: this.pagination._.limit,
            }),
        ),
        tap((list) => {
            this.pagination.set(list.$meta?.pagination);
            this._updateStorage(list.list);
        }),
        map((v) => v.list),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    notifications$ = combineLatest([this._listFromRequest$, this._notificationStorage.$]).pipe(
        debounceTime(10),
        map(([notifications, storage]) => this._actualize(notifications)),
        tap((v) => {
            this.isLoading.set(false);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    // list of unread notifications
    protected unreadNotifications$: Observable<EntData[]> = combineLatest([
        this._updateTopNotificationsList$,
        this.authService.role$,
    ]).pipe(
        filter(([update, role]) => !!role),
        switchMap(([update, role]) =>
            this.disableNotificationsRoles.indexOf(role) === -1
                ? this.entityService.getEntityList$('notifications.notice', {
                      params: {
                          filter: buildFilterStr({
                              state: this.sentState,
                              [this.channelField]: 'websocket',
                          }),
                      },
                      limit: 1000,
                  })
                : of(null),
        ),
        tap((list) => {
            if (!list) return;
            this._updateStorage(list.list);
        }),
        map((v) => (v ? v.list : null)),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    topNotifications$ = combineLatest([
        this.unreadNotifications$,
        this._newNotifications.$,
        this._notificationStorage.$,
    ]).pipe(
        debounceTime(10),
        map(([unreadNotifications, newNotifications, storageData]) =>
            unreadNotifications
                ? this._mergeListItems(unreadNotifications, newNotifications)
                : null,
        ),
        map((notifications) =>
            notifications
                ? this._actualize(notifications).filter(
                      (v) => v.$snapshot.state !== 'read' && !v.$snapshot.is_skip_save,
                  )
                : null,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    blockingNotifications$ = this.topNotifications$.pipe(
        map((notifications) =>
            notifications ? notifications.filter((v) => v.$snapshot.is_blocking_notice) : null,
        ),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    protected _mergeListItems(main: any[], addToBeginElements: any[] = []) {
        const mainList = main;
        addToBeginElements.forEach((element) => {
            if (!mainList.find((v) => v.id === element.id)) {
                mainList.unshift(element);
            }
        });
        return main;
    }

    changeState(ids: string[], state = 'read') {
        this.srv
            .sendFormRequest$('user/change_state_notifications', { state, notifications_id: ids })
            .pipe(
                tap(() => {
                    this._updateStoragePropery(ids, { state });
                    if (state !== 'read') {
                        this._updateTopNotificationsList$.next(true);
                    }
                }),
                take(1),
            )
            .subscribe();
    }

    changeImportance(id: string, importance: boolean) {
        this.srv
            .saveEntity$(
                'notifications.notice',
                id,
                this.srv.generateEntityBody('notifications.notice', id, {
                    is_important: importance,
                }),
            )
            .pipe(
                tap(() => {
                    this._updateStoragePropery([id], { is_important: importance });
                    this._updateTabsCount$.next(true);
                }),
                withLatestFrom(this.tab$),
                tap(([response, tab]) => {
                    if (tab === 'important') this.tab$.next(tab);
                }),
                take(1),
            )
            .subscribe();
    }

    protected _updateStoragePropery(ids, propertyObject) {
        this._updateStorage(
            ids.map((id) => {
                return {
                    ...this._notificationStorage._[id],
                    $snapshot: {
                        ...this._notificationStorage._[id].$snapshot,
                        ...propertyObject,
                    },
                };
            }),
        );
    }

    protected _updateStorage(notifications) {
        const newNotificationsMap = notifications.reduce((acc, notification) => {
            acc[notification.id] = notification;
            return acc;
        }, {});
        this._notificationStorage.set({ ...this._notificationStorage._, ...newNotificationsMap });
    }

    protected _actualize(notifications) {
        return notifications.map((v) => {
            return this._notificationStorage._[v.id] || v;
        });
    }
}
